import { applyMiddleware, combineReducers, createStore, Middleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { MakeStore, createWrapper } from 'next-redux-wrapper';
import logger from 'redux-logger';

import fares, { FaresState } from 'ducks/client/fares';
import productInstances, { ProductInstancesState } from 'ducks/client/productInstances';
import productInstance, { ProductInstanceState } from 'ducks/client/productInstance';
import reservation, { ReservationState } from 'ducks/client/reservation';
import bookmarks, { loadBookmarks, BookmarksState } from 'ducks/client/bookmarks';
import hiddenPopupMessages, {
  loadHiddenPopupMessages,
  HiddenPopupMessagesState,
} from 'ducks/client/hiddenPopupMessages';
import mediaDownloadOrder, { MediaDownloadOrderState } from 'ducks/client/mediaDownloadOrder';
import visitedProductIds, {
  loadVisitedProductIds,
  VisitedProductsState,
} from 'ducks/client/visitedProducts';
import reviews, { ReviewsState } from 'ducks/client/reviews';
import waivers, { WaiversState } from 'ducks/client/waivers';
import coupons, { CouponsState } from 'ducks/client/coupons';
import surveys, { SurveysState } from 'ducks/client/surveys';
import server, { ServerState } from 'ducks/server';
import universal, { UniversalState } from 'ducks/universal';
import customer, { CustomerState } from 'ducks/client/customers';
import product, { ProductState } from 'ducks/client/product';
import footprints, { FootprintsState } from 'ducks/client/footprints';
import availableProductSummaries, {
  AvailableProductSummariesState,
} from 'ducks/client/availableProductSummaries';
import salesOffers, { SalesOffersState } from 'ducks/client/salesOffers';
import marketingAutomationCampaigns, {
  MarketingAutomationCampaignsState,
} from 'ducks/client/marketingAutomationCampaigns';
import reservationReceipt, { ReservationReceiptState } from 'ducks/client/reservationReceipt';
import instantWinEvent, { InstantWinEventState } from 'ducks/client/instantWinEvent';
import instantWin, { InstantWinState } from 'ducks/client/instantWin';
import tracking, { loadVisitorToken, TrackingState } from 'ducks/client/tracking';
import notification, { NotificationState } from 'ducks/client/notification';
import webPushNotification, { WebPushNotificationState } from 'ducks/client/webPushNotification';
import restaurantOrders, { RestaurantOrdersState } from 'ducks/client/restaurantOrders';
import walletPass, { WalletPassState } from 'ducks/client/walletPass';

export interface ReduxState {
  productInstances: ProductInstancesState;
  productInstance: ProductInstanceState;
  reservation: ReservationState;
  reservationReceipt: ReservationReceiptState;
  server: ServerState;
  bookmarks: BookmarksState;
  hiddenPopupMessages: HiddenPopupMessagesState;
  mediaDownloadOrder: MediaDownloadOrderState;
  visitedProductIds: VisitedProductsState;
  reviews: ReviewsState;
  waivers: WaiversState;
  coupons: CouponsState;
  surveys: SurveysState;
  customer: CustomerState;
  product: ProductState;
  availableProductSummaries: AvailableProductSummariesState;
  salesOffers: SalesOffersState;
  marketingAutomationCampaigns: MarketingAutomationCampaignsState;
  instantWinEvent: InstantWinEventState;
  footprints: FootprintsState;
  instantWin: InstantWinState;
  tracking: TrackingState;
  notification: NotificationState;
  webPushNotification: WebPushNotificationState;
  restaurantOrders: RestaurantOrdersState;
  walletPass: WalletPassState;

  fares: FaresState;

  universal: UniversalState;
}

const rootReducer = combineReducers({
  // Client reducers
  fares,
  productInstances,
  productInstance,
  reservation,
  reservationReceipt,
  bookmarks,
  hiddenPopupMessages,
  mediaDownloadOrder,
  visitedProductIds,
  reviews,
  waivers,
  coupons,
  surveys,
  customer,
  product,
  availableProductSummaries,
  salesOffers,
  marketingAutomationCampaigns,
  instantWinEvent,
  footprints,
  instantWin,
  tracking,
  notification,
  webPushNotification,
  restaurantOrders,
  walletPass,

  // Server reducers
  server,

  // Univeral reducers; these are used on both client and server
  universal,
});

// create a makeStore function
const middleware: Array<Middleware<any, Record<string, unknown>, any>> = [reduxThunk];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

export const makeStore: MakeStore<ReduxState> = () =>
  createStore(
    rootReducer,
    {
      tracking: {
        visitorToken: typeof window !== 'undefined' ? loadVisitorToken() : '',
      },
      bookmarks: typeof window !== 'undefined' ? loadBookmarks() : [],
      hiddenPopupMessages: typeof window !== 'undefined' ? loadHiddenPopupMessages() : [],
      visitedProductIds: typeof window !== 'undefined' ? loadVisitedProductIds() : [],
    },
    applyMiddleware(...middleware)
  );

export const wrapper = createWrapper<ReduxState>(makeStore, { debug: false });
