import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { DigitalMap } from 'models/digitalMap';
import { createAction } from 'ducks/actionHelpers';
import { ADD_NOTIFICATION, addNotification } from 'ducks/client/notification';

const FETCH_DIGITAL_MAP_REQUEST = 'FETCH_DIGITAL_MAP_REQUEST';
const FETCH_DIGITAL_MAP_SUCCESS = 'FETCH_DIGITAL_MAP_SUCCESS';
const FETCH_DIGITAL_MAP_FAILURE = 'FETCH_DIGITAL_MAP_FAILURE';

const REFRESH_DIGITAL_MAP_REQUEST = 'REFRESH_DIGITAL_MAP_REQUEST';
const REFRESH_DIGITAL_MAP_SUCCESS = 'REFRESH_DIGITAL_MAP_SUCCESS';
const REFRESH_DIGITAL_MAP_FAILURE = 'REFRESH_DIGITAL_MAP_FAILURE';

const fetchDigitalMapRequest = () => createAction(FETCH_DIGITAL_MAP_REQUEST);
const fetchDigitalMapSuccess = (response: DigitalMap) =>
  createAction(FETCH_DIGITAL_MAP_SUCCESS, response);
const fetchDigitalMapFailure = (err: string) => createAction(FETCH_DIGITAL_MAP_FAILURE, err);

const refreshDigitalMapRequest = () => createAction(REFRESH_DIGITAL_MAP_REQUEST);
const refreshDigitalMapSuccess = (response: DigitalMap) =>
  createAction(REFRESH_DIGITAL_MAP_SUCCESS, response);
const refreshDigitalMapFailure = (err: string) => createAction(REFRESH_DIGITAL_MAP_FAILURE, err);

export const fetchDigitalMap = (
  apiKey: string,
  id: string,
  contentLanguage: string,
  reservationId?: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalMapRequest());

  return axios
    .get(`${config.apiUrl}/digitalmaps/${id}`, {
      params: reservationId ? { reservation_id: reservationId } : {},
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchDigitalMapSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchDigitalMapFailure(err.message));
    });
};

export const refreshDigitalMap = (
  apiKey: string,
  id: string,
  contentLanguage: string,
  reservationId?: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(refreshDigitalMapRequest());

  return axios
    .get(`${config.apiUrl}/digitalmaps/${id}`, {
      params: reservationId ? { reservation_id: reservationId } : {},
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(refreshDigitalMapSuccess(payload));
    })
    .catch((err) => {
      dispatch(refreshDigitalMapFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchDigitalMapRequest>
  | ReturnType<typeof fetchDigitalMapSuccess>
  | ReturnType<typeof fetchDigitalMapFailure>
  | ReturnType<typeof addNotification>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_MAP_FAILURE:
      return action.payload;
    case FETCH_DIGITAL_MAP_REQUEST:
    case FETCH_DIGITAL_MAP_SUCCESS:
      return '';
    default:
      return state;
  }
};

const addPinIndices = (map: DigitalMap) => {
  const pins = map.pins.map((pin, index) => ({ ...pin, index }));
  return { ...map, pins };
};

const map = (state: DigitalMap | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_MAP_SUCCESS:
      return addPinIndices(action.payload);
    case FETCH_DIGITAL_MAP_FAILURE:
      return null;
    case ADD_NOTIFICATION: {
      if (!state || action.payload.type !== 'WAIT_TIME') return state;

      const { restaurant_id, pin_key, wait_time, enabled } = action.payload.payload;

      if (!restaurant_id && !pin_key) return state;

      if (pin_key) {
        const pin = state?.pins.find((pin) => pin.key === pin_key);
        if (pin) {
          return {
            ...state,
            pins: state?.pins.map((pin) =>
              pin.key === pin_key
                ? { ...pin, wait_time: parseInt(wait_time), show_wait_time: enabled !== 'false' }
                : pin
            ),
          };
        }
      } else if (restaurant_id) {
        return {
          ...state,
          pins: state?.pins?.map((pin) =>
            pin.online_order_restaurant_id === restaurant_id
              ? { ...pin, wait_time: parseInt(wait_time), show_wait_time: enabled !== 'false' }
              : pin
          ),
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export interface DigitalMapState {
  error: ReturnType<typeof error>;
  map: ReturnType<typeof map>;
}

export default combineReducers({
  error,
  map,
});
