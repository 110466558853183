import { v4 as uuidv4 } from 'uuid';
import { getCookieConsentValue } from 'lib/util/getCookieConsentValue';
import { combineReducers } from 'redux';
import { createAction } from 'ducks/actionHelpers';
import { buildCookieString } from 'lib/util/buildCookieString';

// Actions
const SET_VISITOR_TOKEN = 'SET_VISITOR_TOKEN';

export const setVisitorToken = (value: string) => createAction(SET_VISITOR_TOKEN, value);

const visitorTokenKey = 'visitor-token';
const sessionTokenKey = 'session-token';
const sessionTokenExpirationKey = 'session-token-exp';
const nonReservationTokenKey = 'nr-token';
const nonReservationTokenExpirationKey = 'nr-token-exp';

export const loadVisitorToken = (): string => {
  if (getCookieConsentValue() !== 'true') {
    return '';
  }

  // Check if visitor token is in the URL
  const urlParams = new URLSearchParams(window.location.search);
  const urlVisitorToken = urlParams.get('_vt');
  if (urlVisitorToken) {
    localStorage.setItem(visitorTokenKey, urlVisitorToken);
    document.cookie = buildCookieString(visitorTokenKey, urlVisitorToken, 90);
    return urlVisitorToken;
  }

  const lsVisitorToken = localStorage.getItem(visitorTokenKey);
  if (lsVisitorToken) {
    return lsVisitorToken;
  }

  const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${visitorTokenKey}=`));
  const cookieVisitorToken = cookie?.split('=')[1];
  if (cookieVisitorToken) {
    return cookieVisitorToken;
  }

  // No visitor token found, create a new one
  const visitorToken = uuidv4();
  localStorage.setItem(visitorTokenKey, visitorToken);

  document.cookie = buildCookieString(visitorTokenKey, visitorToken, 90);

  return visitorToken;
};

export const loadSessionToken = (): string => {
  // Check if session token is in the URL
  const urlParams = new URLSearchParams(window.location.search);
  const urlSessionToken = urlParams.get('_st');
  if (urlSessionToken) {
    localStorage.setItem(sessionTokenKey, urlSessionToken);
    document.cookie = buildCookieString(sessionTokenKey, urlSessionToken, 90);
    return urlSessionToken;
  }

  const sessionCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${sessionTokenKey}=`));
  const expirationCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${sessionTokenExpirationKey}=`));
  if (sessionCookie && expirationCookie) {
    const expiration = new Date(expirationCookie.split('=')[1]);
    if (expiration.getTime() > new Date().getTime()) {
      // Refresh expiration of session to 30 minutes in the future
      document.cookie = buildCookieString(
        sessionTokenExpirationKey,
        new Date(Date.now() + 1000 * 60 * 30).toUTCString()
      );

      return sessionCookie.split('=')[1] || '';
    }
  }

  // No active session, create a new one
  const newSessionToken = uuidv4();
  document.cookie = buildCookieString(sessionTokenKey, newSessionToken);
  document.cookie = buildCookieString(
    sessionTokenExpirationKey,
    new Date(Date.now() + 1000 * 60 * 30).toUTCString()
  );

  return newSessionToken;
};

/**
 * Function to load or create a non-reservation token together with its expiration token using local storage.
 * Defaults to expire in 12 hours.
 * Note: using local storage instead cookies because safari keeps deleting cookies upon closing browser
 * @returns {string} non-reservation token.
 */
export const loadNonReservationToken = (): string | null => {
  // Check if localStorage is available
  if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
    return null;
  }

  const time = 1000 * 60 * 60 * 12;

  // Check if the tokens exist in local storage
  const storedToken = localStorage.getItem(nonReservationTokenKey);
  const storedExpiration = localStorage.getItem(nonReservationTokenExpirationKey);

  if (storedToken && storedExpiration) {
    const expiration = new Date(storedExpiration);
    if (expiration.getTime() > new Date().getTime()) {
      // Still valid, refresh expiration token to 12 hours in the future
      localStorage.setItem(
        nonReservationTokenExpirationKey,
        new Date(Date.now() + time).toUTCString()
      );
      return storedToken;
    } else {
      // Expired, remove tokens
      localStorage.removeItem(nonReservationTokenKey);
      localStorage.removeItem(nonReservationTokenExpirationKey);
    }
  }

  // No active session, create new ones
  const newToken = uuidv4();
  localStorage.setItem(nonReservationTokenKey, newToken);
  localStorage.setItem(nonReservationTokenExpirationKey, new Date(Date.now() + time).toUTCString());

  return newToken;
};

type Action = ReturnType<typeof setVisitorToken>;

// Selector
const visitorToken = (state = '', action: Action) => {
  switch (action.type) {
    case SET_VISITOR_TOKEN:
      return action.payload;
    default:
      return state;
  }
};

export interface TrackingState {
  visitorToken: string;
}

export default combineReducers({
  visitorToken,
});
